import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const theme = {
  colors: {
    primary: {
      50: '#693036',
      100: '#693036',
      200: '#693036',
      300: '#693036',
      400: '#693036',
      500: '#693036',
      600: '#693036',
      700: '#693036',
      800: '#693036',
      900: '#693036',
    },
  },
}

const breakpoints = createBreakpoints({
  sm: '480px',
  md: '720px',
  lg: '1024px',
  xl: '1280px',
  xx: '1440px',
  mw: '1760px',
})

export default extendTheme({ ...theme, breakpoints })
